import React, { useEffect } from "react";
import useStore from "hooks/useStore";
import { useResourceQuery } from "hooks/useResourceQuery";

function CompanyTitle({ open }) {
  const { data: companyList } = useResourceQuery("companies");
  const tradingName = companyList?.[0]?.tradingName || "";

  useEffect(() => {
    if (companyList && companyList.length > 0) {
      useStore.setState({ selectedCompany: companyList[0].id });
    }
  }, [companyList]);

  return (
    <div
      style={{
        width: "250px",
        height: "38px",
        lineHeight: "38px",
        fontSize: "16px",
        paddingLeft: "10px",
        backgroundColor: "transparent",
        color: "white",
        marginLeft: open ? "240px" : "0px",
      }}
    >
      {tradingName}
    </div>
  );
}

export default CompanyTitle;
