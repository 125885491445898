import React, { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DocumentsIcon from "@mui/icons-material/Description";
import PolicyIcon from "@mui/icons-material/Policy";
import useStore from "hooks/useStore";

const PublicMenu = () => {
  const navigate = useNavigate();
  const isUserAdministrator = useStore((state) => state.isUserAdministrator)();
  const isUserPublic = useStore((state) => state.isUserPublic)();

  return (
    <React.Fragment>
      {isUserAdministrator && (
        <ListSubheader component="div" inset>
          Public
        </ListSubheader>
      )}
      {isUserPublic && (
        <>
          <ListItemButton
            onClick={() => startTransition(() => navigate("/public"))}
          >
            <ListItemIcon>
              <DocumentsIcon />
            </ListItemIcon>
            <ListItemText primary="My Contracts" />
          </ListItemButton>
          <ListItemButton
            onClick={() => startTransition(() => navigate("/view-tnc"))}
          >
            <ListItemIcon>
              <PolicyIcon />
            </ListItemIcon>
            <ListItemText primary="Terms & Conditions" />
          </ListItemButton>
        </>
      )}
    </React.Fragment>
  );
};

export default PublicMenu;
