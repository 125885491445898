import React from "react";
import { Grid, Box, Paper } from "@mui/material";
import { Outlet } from "react-router-dom";

const MinimalLayout = ({ maxWidth = "550px" }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ paddingTop: "25px" }}
    >
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        style={{ width: "250px", marginBottom: "1rem" }}
        alt="Logo"
      />

      <Box component={Paper} elevation={2} style={{ maxWidth: maxWidth }} p={4}>
        <Outlet />
      </Box>
    </Grid>
  );
};

export default MinimalLayout;
