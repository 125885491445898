import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import PrivateRoute from "./components/routing/PrivateRoute";
import DashboardLayout from "./layouts/Dashboard/Dashboard";
import MinimalLayout from "layouts/Minimal/Minimal";
import Login from "./pages/login/Login";
const CreateAccount = lazy(() => import("pages/create-account/CreateAccount"));
const ForgotPassword = lazy(() =>
  import("pages/forgot-password/ForgotPassword")
);
const ResetPassword = lazy(() => import("pages/reset-password/ResetPassword"));
const CompaniesList = lazy(() => import("./pages/companies/List"));
const TendersList = lazy(() => import("./pages/contracts/ListTenders"));
const ContractsList = lazy(() => import("./pages/contracts/ListContracts"));
const OthersList = lazy(() => import("./pages/contracts/ListOthers"));
const PublicList = lazy(() => import("./pages/contracts/ListPublic"));
const ReviewsList = lazy(() => import("./pages/reviews/List"));
const ManageAdminUsers = lazy(() => import("pages/administrators/List"));
const ManagePublicUsers = lazy(() => import("pages/publicusers/List"));
const UsersList = lazy(() => import("./pages/users/List"));
const TemplatesListTender = lazy(() =>
  import("./pages/templates/TemplatesListTender")
);
const TemplatesListContract = lazy(() =>
  import("./pages/templates/TemplatesListContract")
);
const TemplatesListOther = lazy(() =>
  import("./pages/templates/TemplatesListOther")
);
const TemplatesListPublic = lazy(() =>
  import("./pages/templates/TemplatesListPublic")
);
const TemplateSections = lazy(() =>
  import("pages/template-sections/TemplateSections")
);
const TemplateQuestions = lazy(() =>
  import("pages/template-questions/TemplateQuestions")
);
const CreateForSection = lazy(() => "pages/pdf/CreateForSection");
const CreateForTemplate = lazy(() => import("pages/pdf/CreateForTemplate"));
const ProjectsList = lazy(() => import("pages/projects/List"));
const SubProjectsList = lazy(() => import("pages/subprojects/List"));
const DraggableList = lazy(() => import("pages/testing/DraggableList"));
const TreeView = lazy(() => import("pages/testing/TreeView"));
const ReviewContract = lazy(() => import("pages/reviews/Review"));
const RedirectRoute = lazy(() => import("components/routing/RedirectRoute"));
const VerifyUser = lazy(() => import("pages/users/Verify"));
const AcceptTermsAndConditions = lazy(() =>
  import("pages/users/AcceptTermsAndConditions")
);
const ViewTermsAndConditions = lazy(() =>
  import("pages/view-tnc/ViewTermsAndConditions")
);

const queryClient = new QueryClient();

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <ConfirmProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<MinimalLayout />}>
                  <Route path="login" element={<Login />} />
                  <Route path="create-account" element={<CreateAccount />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="contract-review" element={<ReviewContract />} />
                  <Route path="verify" element={<VerifyUser />} />
                </Route>

                <Route path="/*" element={<MinimalLayout maxWidth="900px" />}>
                  <Route
                    path="accept-tnc"
                    element={<AcceptTermsAndConditions />}
                  />
                </Route>

                <Route path="/*" element={<PrivateRoute />}>
                  <Route element={<DashboardLayout />}>
                    <Route
                      path="administrators"
                      element={<ManageAdminUsers />}
                    />
                    <Route path="publicusers" element={<ManagePublicUsers />} />
                    <Route path="companies" element={<CompaniesList />} />
                    <Route path="companies/:id/users" element={<UsersList />} />
                    <Route
                      path="tender-templates"
                      element={<TemplatesListTender />}
                    />
                    <Route
                      path="contract-templates"
                      element={<TemplatesListContract />}
                    />
                    <Route
                      path="other-templates"
                      element={<TemplatesListOther />}
                    />
                    <Route
                      path="public-templates"
                      element={<TemplatesListPublic />}
                    />
                    <Route
                      path="templates/:templateId/sections"
                      element={<TemplateSections />}
                    />
                    <Route
                      path="templates/:templateId/questions"
                      element={<TemplateQuestions />}
                    />
                    <Route path="projects" element={<ProjectsList />} />
                    <Route
                      path="projects/:id/subprojects"
                      element={<SubProjectsList />}
                    />
                    <Route path="tenders" element={<TendersList />} />
                    <Route path="contracts" element={<ContractsList />} />
                    <Route path="others" element={<OthersList />} />
                    <Route path="public" element={<PublicList />} />
                    <Route path="reviews" element={<ReviewsList />} />
                    <Route
                      path="pdf/create-for-section"
                      element={
                        <CreateForSection templateId="1" sectionId="1" />
                      }
                    />
                    <Route
                      path="pdf/create-for-template"
                      element={<CreateForTemplate templateId="1" />}
                    />
                    <Route
                      path="view-tnc"
                      element={<ViewTermsAndConditions />}
                    />
                    <Route
                      path="testing/draggable-list"
                      element={<DraggableList />}
                    />
                    <Route path="testing/treeview" element={<TreeView />} />
                    <Route
                      path="*"
                      element={<RedirectRoute />}
                      replace={true}
                    />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ConfirmProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}
