import React, { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ProjectsIcon from "@mui/icons-material/Construction";
import DocumentsIcon from "@mui/icons-material/Description";
import ReviewCheckIcon from "@mui/icons-material/Approval";
import PolicyIcon from "@mui/icons-material/Policy";
import useStore from "hooks/useStore";

const CompanyMenu = () => {
  const navigate = useNavigate();
  const isUserAdministrator = useStore((state) => state.isUserAdministrator)();
  const isUserPublic = useStore((state) => state.isUserPublic)();
  const hasContractsApproverPermissionsInActiveCompany = useStore(
    (state) => state.hasContractsApproverPermissionsInActiveCompany
  )();
  const hasContractsCompilerPermissionsInActiveCompany = useStore(
    (state) => state.hasContractsCompilerPermissionsInActiveCompany
  )();
  return (
    <React.Fragment>
      {isUserAdministrator && (
        <ListSubheader component="div" inset>
          Company
        </ListSubheader>
      )}
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/projects`))}
      >
        <ListItemIcon>
          <ProjectsIcon />
        </ListItemIcon>
        <ListItemText primary="Manage projects" />
      </ListItemButton>
      {(isUserAdministrator ||
        hasContractsCompilerPermissionsInActiveCompany) && (
        <ListItemButton
          onClick={() => startTransition(() => navigate(`/tenders`))}
        >
          <ListItemIcon>
            <DocumentsIcon />
          </ListItemIcon>
          <ListItemText primary="Tenders" />
        </ListItemButton>
      )}
      {(isUserAdministrator ||
        hasContractsCompilerPermissionsInActiveCompany) && (
        <ListItemButton
          onClick={() => startTransition(() => navigate(`/contracts`))}
        >
          <ListItemIcon>
            <DocumentsIcon />
          </ListItemIcon>
          <ListItemText primary="Contracts" />
        </ListItemButton>
      )}
      {(isUserAdministrator ||
        hasContractsCompilerPermissionsInActiveCompany) && (
        <ListItemButton
          onClick={() => startTransition(() => navigate(`/others`))}
        >
          <ListItemIcon>
            <DocumentsIcon />
          </ListItemIcon>
          <ListItemText primary="Others" />
        </ListItemButton>
      )}
      {(isUserAdministrator ||
        hasContractsApproverPermissionsInActiveCompany) && (
        <ListItemButton
          onClick={() => startTransition(() => navigate(`/reviews`))}
        >
          <ListItemIcon>
            <ReviewCheckIcon />
          </ListItemIcon>
          <ListItemText primary="Reviews" />
        </ListItemButton>
      )}
      <ListItemButton
        onClick={() => startTransition(() => navigate("/view-tnc"))}
      >
        <ListItemIcon>
          <PolicyIcon />
        </ListItemIcon>
        <ListItemText primary="Terms & Conditions" />
      </ListItemButton>
    </React.Fragment>
  );
};

export default CompanyMenu;
