import React, { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AdministratorIcon from "@mui/icons-material/ManageAccounts";
import CompaniesIcon from "@mui/icons-material/Business";
import TemplatesIcon from "@mui/icons-material/PlaylistAddCheck";

const AdministratorMenu = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Administrator
      </ListSubheader>
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/administrators`))}
      >
        <ListItemIcon>
          <AdministratorIcon />
        </ListItemIcon>
        <ListItemText primary="Administrators" />
      </ListItemButton>
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/publicusers`))}
      >
        <ListItemIcon>
          <AdministratorIcon />
        </ListItemIcon>
        <ListItemText primary="Public Users" />
      </ListItemButton>
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/companies`))}
      >
        <ListItemIcon>
          <CompaniesIcon />
        </ListItemIcon>
        <ListItemText primary="Companies" />
      </ListItemButton>
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/tender-templates`))}
      >
        <ListItemIcon>
          <TemplatesIcon />
        </ListItemIcon>
        <ListItemText primary="Tender templates" />
      </ListItemButton>
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/contract-templates`))}
      >
        <ListItemIcon>
          <TemplatesIcon />
        </ListItemIcon>
        <ListItemText primary="Contract templates" />
      </ListItemButton>
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/public-templates`))}
      >
        <ListItemIcon>
          <TemplatesIcon />
        </ListItemIcon>
        <ListItemText primary="Public templates" />
      </ListItemButton>
      <ListItemButton
        onClick={() => startTransition(() => navigate(`/other-templates`))}
      >
        <ListItemIcon>
          <TemplatesIcon />
        </ListItemIcon>
        <ListItemText primary="Other templates" />
      </ListItemButton>
    </React.Fragment>
  );
};

export default AdministratorMenu;
