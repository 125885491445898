// Drawer.js
import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AdministratorMenu from "../Menus/AdministratorMenu";
import CompanyMenu from "../Menus/CompanyMenu";
import PublicMenu from "../Menus/PublicMenu";
import useStore from "hooks/useStore";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DrawerContent({ open, toggleDrawer }) {
  const selectedCompany = useStore((state) => state.selectedCompany);
  const isUserAdministrator = useStore((state) => state.isUserAdministrator)();
  const isUserPublic = useStore((state) => state.isUserPublic)();

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <>
          {open && (
            <img
              src={process.env.PUBLIC_URL + "/logo.png"}
              style={{ height: "95px" }}
              alt="Logo"
            />
          )}
        </>
        <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {isUserAdministrator && (
          <>
            <AdministratorMenu />
            <Divider sx={{ my: 1 }} />
          </>
        )}
        {(!isUserAdministrator || selectedCompany) && !isUserPublic && (
          <>
            <CompanyMenu />
            <Divider sx={{ my: 1 }} />
          </>
        )}
        {isUserPublic && (
          <>
            <PublicMenu />
            <Divider sx={{ my: 1 }} />
          </>
        )}
      </List>
    </Drawer>
  );
}

export default DrawerContent;
